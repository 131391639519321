<template>
  <!-- Start code header from here -->
  <div>
    <div id="header">
      <div class="header-m">
        <div class="header-m-left">
          <router-link
            :to="'/app'"
            class="text-decoration-none"
          >
            <i class="fa fa-home fa-lg c-pointer" />
          </router-link>
        </div>
        <div class="header-m-right">
          <notifications />
          <div
            v-click-outside="hide"
            class="header-m-user"
          >
            <div
              class="c-pointer"
              @click="toggle"
            >
              <img
                src="\images\image_user.png"
                class="profile-menu"
              >
            </div>
            <div
              v-show="isActiveBtnUser"
              class="action-user"
            >
              <div class="row">
                <div class="col-5">
                  <img
                    src="\images\image_user.png"
                    class="action-user-img"
                  >
                </div>
                <div class="col-7 pl-0 col-content">
                  <div>
                  <div class="content-close">
                    <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="hide"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>       
                  </div>
                    <div class="content-name">
                      {{ myProfile.name }}
                    </div>
                    <div class="content-email">
                      {{ myProfile.email }}
                    </div>
                  </div>
                  <router-link :to="'../../profile'">
                    <button
                      class="btn btn-primary btn-sm btn-block mx-0"
                      @click="hide"
                    >
                      View Detail
                    </button>
                  </router-link>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <div class="list-group list-group-flush">
                    <a
                      href="#"
                      class="list-group-item list-group-item-action"
                      @click="userLogout"
                    >Sign Out</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../plugins/axios"
import ClickOutside from 'vue-click-outside'

import notifications from "./Notifications"

export default {
  name: "CHeader",
  components: {
    notifications,
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      // Menu Header
      isActiveBtnUser: false,
      isSetting: false,

      myProfile: [],
    };
  },

  created () {
    this.load_profile();
  },

  methods: {
    toggle () {
      this.isActiveBtnUser =  !this.isActiveBtnUser
    },
    hide () {
      this.isActiveBtnUser = false
    },

    userLogout() {
      this.$store.dispatch('moduleAuth/LOGOUT')
    },

    load_profile() {
      let vi = this;
      let endpoint = `user/profile`;
      API.get(endpoint)
        .then((response) => {
          const meta = response.data;
          const data = meta.data;
          if (meta.code === 200) {
            vi.myProfile = data;
            vi.isSetting = true;
          }
        })
    }
  },
};
</script>

<style></style>
