import API from '../../plugins/axios'

const moduleCountry = {
  namespaced: true,
  state: {
    dataDropdown: Object,
  },
  getters: {

  },
  mutations: {
    SET_DROPDOWN (state, payload) {
      state.dataDropdown = payload
    }
  },
  actions: {
    async LOAD_DROPDOWN ({commit}) {
      await API.get('master/country/dropdown')
      .then((res) => {
        let data = res.data.data
        commit('SET_DROPDOWN', data)
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }
}
export default moduleCountry
