import API from '../../plugins/axios'
import { SwalSuccess, SwalWarning } from "../../plugins/sweetalert2";

const vessels =  {
  namespaced: true,
  state: {
    listData: [],
    selectedVessel: {
      id: '',
      name: '',
      callsign: '',
      imo: '',
      stamp_name: '',
      company: '',
      shipyard: '',
      build_year: '',
      country: '',
      classification: '',
      vessel_type: '',
      size_loa: '',
      size_lbp: '',
      size_width: '',
      size_depth: '',
      summer_draft: '',
      winter_draft: '',
      tropical_draft: '',
      freshwater_draft: '',
      gross_tonnage: '',
      net_tonnage: '',
      deadweight: '',
      load_capacity: '',
      main_engine: '',
      main_engine_build_year: '',
      main_engine_number: '',
      horsepower: '',
      fuel_type: '',
      aux_engine: '',
      aux_engine_build_year: '',
      aux_engine_number: ''
    },
    selectedVesselType: null,
    license: {},
    userCreate: {},

    selectedParticulars: [],
    recordsPaymentLicense: [],
    particularsOption: {
      uploadPrecentage: 0,
      sizeCek: 0,
      extFiles: [".jpg", ".jpeg", ".pdf", ".png"],
    },

    buildYear: [],
    isLoading: false
  },
  getters: {
    GET_SELECTED (state) { state.selectedVessel },
    GET_PARTICULARS (state) { state.selectedParticulars }
  },
  mutations: {
    SET_SELECTED (state, payload) {
      state.selectedVessel = {
        id: payload.id,
        name: payload.name,
        callsign: payload.callsign,
        imo: payload.imo_number,
        stamp_name: payload.stamp,
        company: payload.company.id,
        shipyard: payload.shipyard_name,
        build_year: payload.build_year,
        country: payload.country.name,
        classification: payload.classification.name,
        vessel_type: payload.vessel_type.name,
        size_loa: payload.size.loa,
        size_lbp: payload.size.lbp,
        size_width: payload.size.width,
        size_depth: payload.size.depth,
        summer_draft: payload.draft.summer,
        winter_draft: payload.draft.winter,
        tropical_draft: payload.draft.tropical,
        freshwater_draft: payload.draft.freshwater,
        gross_tonnage: payload.weight.gross,
        net_tonnage: payload.weight.net,
        deadweight: payload.weight.deadweight,
        load_capacity: payload.load_capacity,
        main_engine: payload.engine.main.engine,
        main_engine_build_year: payload.engine.main.build_year,
        main_engine_number: payload.engine.main.number,
        horsepower: payload.engine.horsepower,
        fuel_type: payload.fuel_type.name,
        aux_engine: payload.engine.aux.engine,
        aux_engine_build_year: payload.engine.aux.build_year,
        aux_engine_number: payload.engine.aux.number
      }
      state.selectedVesselType = payload.vessel_type.id
      state.license = payload.license
      state.license['is_valid'] = payload.is_valid
      state.license['notes'] = payload.notes
      state.userCreate = payload.user_create
      state.userCreate['created_at'] = payload.created_at

      const data = payload.payment_license
      state.recordsPaymentLicense = []
      data.forEach(el => {
        state.recordsPaymentLicense.push({
          id: el.id,
          user_request: el.user_request,
          start_date: el.start_date,
          end_date: el.end_date,
          request_payment_license: el.request_payment_license,
          status: el.status === 'Menunggu Verifikasi Admin' ? 'Waiting Confirmation' :
                  el.status === 'Menunggu Follow Up' ? 'Follow Up' :
                  el.status === 'Batal' ? 'Cancelled' : 'Success',
          variant: el.status === 'Menunggu Verifikasi Admin' ? 'primary' :
                   el.status === 'Menunggu Follow Up' ? 'warning' :
                   el.status === 'Batal' ? 'danger' : 'success',

        })
      })
    },
    SET_PARTICULARS (state, payload) { state.selectedParticulars = payload },
    DELETE_SELECTED_PARTICULAR (state, payload) {
      state.selectedParticulars.splice(state.selectedParticulars.indexOf(payload), 1)
    },
    COUNT_BUILD_YEAR (state) {
      let yearNow = new Date().getFullYear();
      let minYear = yearNow - 50;
      while (minYear <= yearNow) {
        state.buildYear.push(minYear);
        minYear++;
      }
    },
    SET_LOADING (state, payload) { state.isLoading = payload },
    RESET_FORM (state) {
      state.selectedVessel = {
        id: '',
        name: '',
        callsign: '',
        imo: '',
        stamp_name: '',
        company: '',
        shipyard: '',
        build_year: '',
        country: '',
        classification: '',
        vessel_type: '',
        size_loa: '',
        size_lbp: '',
        size_width: '',
        size_depth: '',
        summer_draft: '',
        winter_draft: '',
        tropical_draft: '',
        freshwater_draft: '',
        gross_tonnage: '',
        net_tonnage: '',
        deadweight: '',
        load_capacity: '',
        main_engine: '',
        main_engine_build_year: '',
        main_engine_number: '',
        horsepower: '',
        fuel_type: '',
        aux_engine: '',
        aux_engine_build_year: '',
        aux_engine_number: ''
      }
      state.selectedParticulars = []
    },
    SET_UPLOAD_PERCENTAGE (state, payload) {
      state.particularsOption.uploadPrecentage = payload
    },
    SET_LIST_DATA (state, payload) {
      state.listData = payload
    }
  },
  actions: {
    async LOAD_SELECTED ({commit}, payload) {
      let paramsOne = {id: payload}
      let paramsTwo = {vessel: payload}
      // commit('COUNT_BUILD_YEAR')
      commit('SET_LOADING', true)
      commit('RESET_FORM')

      await API.get('master/vessel/detail', { params: paramsOne })
      .then((res) => {
        const data = res.data.data
        commit('SET_SELECTED', data)
      })
      .catch((err) => {
        commit('SET_SELECTED', Object)
        console.log(err)
      })

      await API.get('master/vessel/doc', { params: paramsTwo })
      .then((res) => {
        const data = res.data.data
        commit('SET_PARTICULARS', data)
      })
      .catch((err) => {
        commit('SET_PARTICULARS', [])
        console.log(err)
      })
      commit('SET_LOADING', false)
    },

    async UPDATE_SELECTED ({commit, dispatch}, payload) {
      let params = payload
      commit('SET_LOADING', true)
      await API.patch('master/vessel/update-custom', params)
      .then((res) => {
        SwalSuccess (res.data.message)
        commit('RESET_FORM')
        // commit('SET_SHOW_FORM_UPDATE')
        dispatch('LOAD_SELECTED', params.id)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })

    },
    async UPLOAD_PARTICULARS ({commit, state}, payload) {
      let formFiles = new FormData()
      for (let i = 0; i < payload.length; i++) {
        formFiles.append("id", state.selectedVessel.id)
        formFiles.append("files[" + i + "]", payload[i]);
      }
      await API.post("master/vessel/doc/upload", formFiles,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            },
            //FUNGSI INI YANG MEMILIKI PERAN UNTUK MENGUBAH SEBERAPA JAUH PROGRESS UPLOAD FILE BERJALAN
            onUploadProgress: function(progressEvent) {
              //DATA TERSEBUT AKAN DI ASSIGN KE VARIABLE progressBar
              commit('SET_UPLOAD_PERCENTAGE', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)))
            }.bind(this),
          })
        .then(() => {
          SwalSuccess ('Anda berhasil menambahkan file baru')
        })
        .catch((err) => {
          if (err.response.status !== 401) SwalWarning(err.response.data.message)
        }).then(() => {
          commit('SET_UPLOAD_PERCENTAGE', 0)
        })
    },
    async DELETE_PARTICULAR ({dispatch}, payload) {
      const params = payload
      await API.delete('master/vessel/doc/delete', {params})
        .then(res => {
          SwalSuccess (res.data.message, () => dispatch('LOAD_SELECTED', payload.vessel))
        })
        .catch(err => {
          if (err.response.status !== 401) SwalWarning(err.response.data.message)
        })

    },
    async ADD_VESSEL ({commit}, payload) {
      let params = payload
      // Reset Form
      commit('RESET_FORM')
      await API.get('master/vessel/update', {params: params})
      .then(() => {
      })
      .catch((err) => {
        console.log('ADD Vessel', err.response.data.message)
      })
    }
    // async LOAD_VESSELS () {
    //   let params = {
    //     'draw': '0',
    //     // 'order[0][column]': '',
    //     // 'order[0][dir]': 'desc',
    //     // 'search[value]': '',
    //     // 'search[regex]': 'false',
    //     'start': '0',
    //     'length': '5',
    //     'vessel_type': '2',
    //     'tugboat': 'F',
    //     'range_min': '10',
    //     'range_max': '100',

    //     'columns[0][data]': '0',
    //     'columns[0][name]': 'id',
    //     'columns[0][searchable]':'true',
    //     'columns[0][orderable]':'true',
    //     'columns[0][search][value]': '',
    //     'columns[0][search][regex]': 'false',
    //     'columns[1][data]': '1',
    //     'columns[1][name]': 'name',
    //     'columns[1][searchable]': 'true',
    //     'columns[1][orderable]': 'true',
    //     'columns[1][search][value]': '',
    //     'columns[1][search][regex]': 'false',

    //     'columns[2][data]': '2',
    //     'columns[2][name]': 'load_capacity',
    //     'columns[2][searchable]': 'true',
    //     'columns[2][orderable]': 'true',
    //     'columns[2][search][value]': '',
    //     'columns[2][search][regex]': 'false',

    //     'columns[3][data]': '3',
    //     'columns[3][name]': 'created_at',
    //     'columns[3][searchable]': 'true',
    //     'columns[3][orderable]': 'true',
    //     'columns[3][search][value]': '',
    //     'columns[3][search][regex]': 'false',

    //     'columns[4][data]': '4',
    //     'columns[4][name]': 'classification',
    //     'columns[4][searchable]': 'true',
    //     'columns[4][orderable]': 'true',
    //     'columns[4][search][value]': '',
    //     'columns[4][search][regex]': 'false',

    //     'columns[5][data]': '5',
    //     'columns[5][name]': 'vessel_type',
    //     'columns[5][searchable]': 'true',
    //     'columns[5][orderable]': 'true',
    //     'columns[5][search][value]': '',
    //     'columns[5][search][regex]': 'false',

    //     'columns[6][data]': '6',
    //     'columns[6][name]': 'require_tugboat',
    //     'columns[6][searchable]': 'true',
    //     'columns[6][orderable]': 'true',
    //     'columns[6][search][value]': '',
    //     'columns[6][search][regex]': 'false',

    //     'columns[7][data]': '7',
    //     'columns[7][name]': 'valid',
    //     'columns[7][searchable]': 'true',
    //     'columns[7][orderable]': 'true',
    //     'columns[7][search][value]': '',
    //     'columns[7][search][regex]': 'false',

    //     'columns[8][data]': '8',
    //     'columns[8][name]': 'user_create',
    //     'columns[8][searchable]': 'true',
    //     'columns[8][orderable]': 'true',
    //     'columns[8][search][value]': '',
    //     'columns[8][search][regex]': 'false'
    //   }
    //   await api ()
    //   .get('master/vessel', {params: params})
    //   .then((res) => {
    //     let data = res.data.data
    //     console.log(data)
    //   })
    //   .catch((err) => {
    //     console.log(err.response.data.message)
    //   })
    // }
  },
}

export default vessels
