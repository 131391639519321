import Vue from "vue"
import Vuex from "vuex"

import Utils from '../mixins/utils'
import { icon } from "leaflet"
import API from "@/plugins/axios"


import moduleAuth from './auth'
import moduleVessel from './masters/master-vessels'
import moduleVesselType from "./masters/master-vessel-type"
import moduleCountry from './masters/master-country'
import moduleClassifications from './masters/master-classifications'
import moduleNotifications from './notifications'
import moduleInbox from "./inbox";

import moduleBuyLicense from './transactions/BuyLicense'

Vue.use(Vuex);

const isMobile = window.outerWidth <= 768

const store = {
    modules: {
        moduleAuth,

        moduleVessel,
        moduleVesselType,
        moduleCountry,
        moduleClassifications,

        moduleBuyLicense,

        moduleNotifications,
        moduleInbox
    },
    state: {
        isLoading: false,

        showLeftbar: isMobile ? false : true,
        activePanelCargoList: false,
        modalDetailCargo: false,
        cargoLoc: [],
        paramsBooking: {},
        tempCargoList: [],
        cargoList: [],
        currentZoom: 0,
        currentCenter: [0, 0],
        currentBounds: [[14.43468021529728, 141.06445312500003], [-17.602139123350838, 77.78320312500001]],
        paddingTopLeft: [isMobile ? 0 : 150, 100], // [left, top]
        paddingBottomRight: [isMobile ? 0 : 250, 50], // [right, bottom]
        polyline: {
            show: false,
            latlngs: [],
            color: '#1c344f',
            dash: "4"
        },
        destinationMarker: {
            latlngs: [],
            show: false,
            name: ''
        },
        fromMarker: {
            data: {},
            latlngs: [],
            show: false,
            name: ''
        },
        adsMarkers: {
            data: {},
            show: false,
            name:''
        },
        mapOptions: {
            zoomSnap: 0.25,
            zoomDelta: 0.25,
            zoomControl: false
        },
        tileProviders: [
            {
                name: 'Default',
                visible: false,
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                attribution:'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            },
            {
                name: 'MapBox',
                visible: true,
                url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZmF0aGFsZmF0aDMwIiwiYSI6ImNrM3NlcXB5NTA1cGszbW80ZDJnNTV3azEifQ.b_7rn9WvpIqbhXAuYZQlYw',
                attribution:'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            },
        ],
        icon: icon({
            iconUrl: "/images/icon_marker_map.png",
            iconSize: [32, 37],
            iconAnchor: [16, 37]
        }),
        IconCargo: icon({
            iconUrl: "/images/icon_marker_map.png",
            iconSize: [32, 37],
            iconAnchor: [16, 37]
        }),
        IconVessel: icon({
            iconUrl: "/images/vessel-pin.png",
            iconSize: [32, 37],
            iconAnchor: [16, 37]
        }),
        currentCargo : null,
        currentVesselTrail : null,
        trailHistory : null,
        trailHistoryLabel : null,
        showOfferingListDocuments : null,
        showOfferingStatus : null,
        showManifest : null,
        showManifestLogs : null,
        showEngineHealthReport : null,
    },
    getters: {
        GET_LOADING: state =>  state.isLoading
    },
    mutations: {
        SET_SHOW_ENGINE_HEALTH_REPORT (state, payload) {
            state.showEngineHealthReport = payload
        },
        SET_SHOW_MANIFEST (state, payload) {
            state.showManifest = payload
        },
        SET_SHOW_MANIFEST_LOGS (state, payload) {
            state.showManifestLogs = payload
        },
        SET_SHOW_OFFERING_LIST_DOCUMENTS (state, payload) {
            state.showOfferingListDocuments = payload
        },
        SET_SHOW_OFFERING_STATUS (state, payload) {
            state.showOfferingStatus = payload
        },
        SET_TRAIL_HISTORY (state, payload) {
            state.trailHistory = payload
        },
        SET_TRAIL_HISTORY_LABEL (state, payload) {
            state.trailHistoryLabel = payload
        },
        SET_CURRENT_VESSEL_TRAIL (state, payload) {
            state.currentVesselTrail = payload
        },
        SET_CURRENT_CARGO (state, payload) {
            state.currentCargo = payload
        },
        SET_LOADING (state) {
            state.isLoading = !state.isLoading
        },
        SET_MODAL_DETAIL_CARGO (state, payload) {
            state.modalDetailCargo = payload
        },
        SET_PANEL_CARGO_LIST (state, payload) {
            state.activePanelCargoList = payload
        },
        TOOGLE_NAV (state) {
            state.showLeftbar = !state.showLeftbar
            if (state.showLeftbar == true) {
                state.showLeftbar = true
            } else {
                state.showLeftbar = false
            }
        },

        ZOOM_UPDATED (state, zoom) {
            state.currentZoom = zoom;
        },
        CENTER_UPDATED (state, center) {
            state.currentCenter = center;
        },
        RESET_BOUND (state) {
            state.currentBounds = [[14.43468021529728, 141.06445312500003], [-17.602139123350838, 77.78320312500001]]
        },
        SET_BOUND (state, bounds) {
            state.currentBounds = bounds
        },
        SET_ADS_MARKER (state, payload) {
            if (payload) {
                state.adsMarkers.data = payload
                state.adsMarkers.show = true
            } else {
                state.adsMarkers.data = []
                state.adsMarkers.show = false
            }
        }
    },
    actions: {
        UPDATE_CARGO ({state, commit, dispatch}, payload) {
            state.cargoList = []
            dispatch('RESET_ROUTE')
            commit('SET_PANEL_CARGO_LIST', false)

            state.cargoLoc = Utils.removeDuplicate(payload.data, (it) => it.port.from.id) // hanya yang uniq menghindari duplicate, untuk marker yang di tampilkan
            state.tempCargoList = payload.data
            state.paramsBooking = payload.params
        },
        RESET_ROUTE ({state, commit}) {
            commit('RESET_BOUND')
            state.polyline.show = false
            state.destinationMarker.show = false
            state.fromMarker.show = false

            state.fromMarker.latlngs = []
            state.destinationMarker.latlngs = []
        },
        FIND_ROUTE ({state}, data) {

            let portFrom = [data.port.from.location.latitude, data.port.from.location.longitude ] // Declaration port from
            let portDestination = [data.port.destination.location.latitude, data.port.destination.location.longitude ] // Declaration port destination
            state.polyline.latlngs = [ portFrom, portDestination ] // set polyline garis lurus mulai dari latlngs port start hingga port destination

            state.fromMarker.latlngs = portFrom // set marker latlng port, berdasarkan latlng port start cargo
            state.fromMarker.data = data
            state.fromMarker.name = data.port.from.name

            state.destinationMarker.latlngs = portDestination // set marker latlng port, berdasarkan latlng port destination cargo
            state.destinationMarker.name = data.port.destination.name

            state.polyline.show = true // menampilkan polyline
            state.destinationMarker.show = true // menampilkan marker destination
            state.fromMarker.show = true // menampilkan marker destination
            state.showLeftbar = false

            state.currentBounds = [portFrom, portDestination] // set bound untuk menyesuaikan latlng port start and latlngs port destination

        },
        SHOW_CARGO_LIST ({state, commit}, payload) {
            state.cargoList = []
            state.tempCargoList.map(el => {
              if (el.port.from.id == payload.port.from.id) state.cargoList.push(el) // cargoList dibuat untuk destination yang beda, menghindari penumpukan marker
            })
            commit('SET_PANEL_CARGO_LIST', true)
        },

        LOAD_ADS ({commit}, payload) {
            if (payload) {
                commit('SET_ADS_MARKER')
                commit('SET_LOADING')
                const params = {
                    ads_category: payload.id
                }
                return new Promise ((resolve,reject) => {
                    API.get('publicity/product/search', {params})
                    .then(res => {
                        const data = res.data.data
                        commit('SET_ADS_MARKER', data)
                        commit('RESET_BOUND')
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
                    .finally(() => {
                        commit('SET_LOADING')
                    })
                })
                
            } else {
                commit('SET_ADS_MARKER')
            }
        }
    }
};
export default new Vuex.Store(store);
