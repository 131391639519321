<template>
  <div class="header-m-notif">
    <div v-click-outside="hide" class="icon-menu c-pointer" @click="toggle">
      <i class="fa fa-bell fa-lg" />
      <div v-show="count > 0" class="counter-notif">
        <small>{{ count }}</small>
      </div>
    </div>
    <div v-show="isActiveBtnNotif" class="action-notif">
      <div class="header-notif">
        <div class="list-group">
          <li class="list-group-item">
            <div class="d-flex justify-content-start align-items-center">
              <h5>Notification</h5>
              <span v-show="count > 0" class="badge badge-primary ml-2">{{
                count
              }}</span>
            </div>
            <span class="float-right c-pointer" @click="markAllRead"
              >Mark all read</span
            >
          </li>
        </div>
      </div>
      <div class="list-group list-notif">
        <li
          v-if="!listNotifications.length"
          class="list-group-item d-flex justify-content-center"
        >
          <caption>
            There is no notification
          </caption>
        </li>
        <a
          v-for="(item, index) in listNotifications"
          :key="index"
          href="#"
          class="list-group-item list-group-item-action w-100"
          @click="markOneRead(item.id)"
        >
          <div class="row">
            <div class="col-2 d-flex align-items-center">
              <img src="\images\image_user.png" class="photo-notif" />
            </div>
            <div class="col-8 d-flex flex-column align-self-center">
              <p class="mb-1">{{ item.data.name }}</p>
              <p class="mb-1">
                <small>{{ item.data.data }}</small>
              </p>
              <p class="mb-1">
                <small>{{ item.created_at | dtFormat('DD/MM/Y HH:mm')}}</small>
              </p>
            </div>
            <div class="col-2 d-flex align-items-center">
              <span v-show="!item.read_at" class="dot" />
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// import Pusher from "pusher-js"
import ClickOutside from "vue-click-outside";
import API from "../plugins/axios";
import moment from 'moment';
export default {
  directives: {
    ClickOutside,
  },
  data() {
    return {
      isActiveBtnNotif: false,
      listNotifications: [],
      count: null,
      dataUser: JSON.parse(localStorage.getItem("userData")),
    };
  },
  created() {
    this.getNotification();

    window.Echo.private(`users.${this.dataUser.user_id}`).notification(() => {
      this.notifSound();
      this.getNotification();
    });
  },
  methods: {
    toggle() {
      this.isActiveBtnNotif = !this.isActiveBtnNotif;
    },
    hide() {
      if (window.screen.width >= 481) {
        this.isActiveBtnNotif = false;
      }
    },
    notifSound() {
      const path = "/sounds/juntos.mp3";
      const audio = new Audio(path);
      audio.play();
    },
    async getNotification() {
      const vm = this;
      await API.get("user/notifications")
        .then((res) => {
          vm.listNotifications = res.data.notif;
          vm.count = res.data.unread;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async markAllRead() {
      const vm = this;
      await API.get("user/mark-all-notifications")
        .then(() => {
          vm.getNotification();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async markOneRead(id) {
      const vm = this;
      const params = {
        id_notif: id,
      };
      await API.get("user/read-notification", { params: params })
        .then(() => {
          vm.getNotification();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  filters: {
    dtFormat (dt, format) {
      return moment.utc(dt).format(format)
    }
  }
};
</script>

<style></style>
