import API from '../plugins/axios'

const moduleNotifications = {
  namespaced: true,
  state: {
    dataNotifications: Object,
    readedNotifications: null
  },
  getters: {

  },
  mutations: {
    SET_NOTIFICATIONS (state, payload) {
      state.dataNotifications = payload
    },
    SET_READED_NOTIFICATIONS (state, payload) {
      if (!payload) {
        state.readedNotifications = state.dataNotifications.some(x => x.read_at !== null && state.dataNotifications.length)
      } else {
        state.readedNotifications = payload
      }
      return state.readedNotifications = payload
    }
  },
  actions: {
    async LOAD_NOTIFICATIONS ({commit}) {
      await API.get('user/notifications')
      .then(res => {
        commit('SET_NOTIFICATIONS', res.data)
      }).catch(err =>{
          console.log(err)
      })
    }
  }
}
export default moduleNotifications
