import API from '../../plugins/axios'

const moduleVesselType = {
  namespaced: true,
  state: {
    dataDropdown: [],
  },
  getters: {

  },
  mutations: {
    SET_DROPDOWN (state, payload) {
      state.dataDropdown = payload
    }
  },
  actions: {
    async LOAD_DROPDOWN ({commit}) {
      await API.get('master/vessel-type/dropdown')
      .then((res) => {
        let data = res.data.data
        commit('SET_DROPDOWN', data)
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }
}
export default moduleVesselType
