import API from '../../plugins/axios'
import Router from '@/routes/router'
import {SwalSuccess, SwalWarning} from "../../plugins/sweetalert2";

const moduleBuyLicense = {
  namespaced: true,
  state: {
    dataVessel: Object,
    isLoading: false,
    dataLicense: Object
  },
  getters: {
    getDataVessel: (state) => { return state.dataVessel },
    getLoading: (state) => { return state.isLoading }
  },
  mutations: {
    SET_DATA_VESSEL (state, payload) {
      state.dataVessel = payload
    },
    SET_DATA_LICENSE (state, payload) {
      state.dataLicense = payload
    },
    SET_LOADING (state, payload) {
      state.isLoading = payload
    }
  },
  actions: {

    async LOAD_DETAIL_VESSEL ({commit}, payload) {
      commit('SET_LOADING', true)
      await API.get('master/vessel/detail', { params:{id: payload} })
      .then((res) => {
        let data = res.data.data
        commit('SET_DATA_VESSEL', data)
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
    },

    async CREATE_LICENSE ({commit}, payload) {
      commit('SET_LOADING', true)
      await API.post('transaction/payment/license-vessel/create', payload)
      .then((res) => {
        commit('SET_DATA_LICENSE', res.data.data)
        SwalSuccess (res.data.message, () => Router.push({ name: 'payment-license' }))
      })
      .catch((err) => {
        if (err.response.status !== 401) SwalWarning (err.response.data.message)
      })
      .then(() => {
        commit('SET_LOADING', false)
      })
    }
  }
}
export default moduleBuyLicense
