import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/store"
import {
  SwalFailed
} from './../plugins/sweetalert2'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "*",
      name: "notfound",
      component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/NotFoundPage.vue"),
      meta: {
        title: "Page not found",
        loginRequired: true
      }
    },
    {
      path: "/login",
      name: "login",
      component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/LoginPage.vue"),
      meta: {
        title: "Zonasea Login Page",
        guest: true
      }
    },
    {
      path: "/auth/signin/:token",
      name: "auth-signin",
      component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/auth/SigninPage.vue"),
      meta: {
        title: "Zonasea Signin Page",
        guest: true
      }
    },
    {
      path: "/auth/wizard/company/:token",
      name: "auth-wizard-company",
      component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/auth/CreateCompanyPage.vue"),
      meta: {
        title: "Zonasea Create Company Page",
        guest: true
      }
    },
    {
      path: "/register",
      name: "register",
      component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/RegisterPage.vue"),
      meta: {
        title: "Zonasea Sign Up",
        guest: true
      }
    },
    {
      path: "/resend-email-verification/:email",
      name: "resend-email-verification",
      component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/ResendEmailVerification.vue"),
      meta: {
        title: "Zonasea Resend Email",
        guest: true
      }
    },
    {
      path: "/reset-password",
      name: "resetpassword",
      component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/ResetPasswordPage.vue"),
      meta: {
        title: "Zonasea Reset Password",
        guest: true
      }
    },
    {
      path: "/",
      redirect: "/login"
    },
    {
      path: "/app",
      name: "app",
      redirect: "/app/vessel-list",
      component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/MainApp.vue"),
      meta: {
        title: "Zonasea Ship Owner",
        loginRequired: true
      },
      children: [
        {
          path: "vessel-list",
          name: "vessel-list",
          component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/main/VesselList.vue"),
          meta: {
            title: "Zonasea Ship Owner",
            loginRequired: true
          }
        },
        {
          path: "cargo-finder",
          name: "cargo-finder",
          component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/main/CargoFinder.vue"),
          meta: {
            title: "Zonasea Ship Owner",
            loginRequired: true
          }
        },
        {
          path: "payment-license",
          name: "payment-license",
          component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/main/PaymentLicense.vue"),
          meta: {
            title: "Zonasea Ship Owner",
            loginRequired: true
          }
        },
        {
          path: "booking-list",
          name: "booking-list",
          redirect: "/app/booking-list/ongoing",
          component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/main/BookingList.vue"),
          meta: {
            title: "Zonasea Ship Owner",
            loginRequired: true
          },
          children: [
            {
              path: "ongoing",
              name: "ongoing",
              component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/main/BookingOngoing.vue"),
              meta: {
                title: "Zonasea Ship Owner",
                loginRequired: true
              },
            },
            {
              path: "history",
              name: "history",
              component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/main/BookingHistory.vue"),
              meta: {
                title: "Zonasea Ship Owner",
                loginRequired: true
              },
            }
          ]
        },
        {
          path: "inbox",
          name: "inbox",
          component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/main/Inbox.vue"),
          meta: {
            title: "Zonasea Ship Owner",
            loginRequired: true
          }
        },

      ]
    },
    {
      path: "/account",
      name: "account",
      component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/account/Account"),
      meta: {
        title: "Zonasea Account",
        loginRequired: true
      },
      children: [
        {
          path: "/profile",
          name: "profile",
          component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/account/Profile.vue"),
          meta: {
            title: "Zonasea Profile",
            loginRequired: true
          }
        }
      ]
    },

    // Router Vessels
    {
      path: "/vessels",
      name: "vessels",
      component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/vessels/Vessels.vue"),
      meta: {
        title: "Zonasea Profile",
        loginRequired: true
      },
      children: [
        {
          path: 'create',
          component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/vessels/Create.vue"),
          meta: {
            title: "Zonasea Create New Vessel",
            loginRequired: true
          }
        },
        {
          path: 'detail/:id',
          name: "detail-vessel",
          component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/vessels/Detail.vue"),
          meta: {
            title: "Zonasea Detail Vessel",
            loginRequired: true
          }
        },
        {
          path: 'buy-license/:id',
          name: "buy-license",
          component: () => import('@/pages/vessels/BuyLicense'),
          meta: {
            title: "Zonasea Payment License",
            loginRequired: true
          }
        }
      ]
    },

    // Router Vessels Engine
    {
      path: "/vessel-engines",
      name: "vessel-engines",
      component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/vessels/Vessels.vue"),
      meta: {
        title: "Zonasea Profile",
        loginRequired: true
      },
      children: [
        {
          path: 'list/:id',
          name: "list-vessel-engines",
          component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/vessels/engine/List.vue"),
          meta: {
            title: "Zonasea List Vessel Engines",
            loginRequired: true
          }
        },
        {
          path: 'details/:vesselId/:vesselEngineId',
          name: "details-vessel-engine",
          component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/vessels/engine/Detail.vue"),
          meta: {
            title: "Zonasea Vessel Engine Details",
            loginRequired: true
          }
        },
        {
          path: 'report/:vesselId/:vesselEngineId',
          name: "report-vessel-engine",
          component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/vessels/engine/Report.vue"),
          meta: {
            title: "Zonasea Vessel Engine Report",
            loginRequired: true
          }
        }
      ]
    }
  ]
});

router.afterEach (() => {
  const appLoading = document.getElementById ('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach ((to, from, next) => {
  window.scrollTo(0,0);
  document.title = to.meta.title;

  if (to.matched.some(record => record.meta.loginRequired)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!localStorage.getItem('token') && !store.getters.loggedIn) {
      next({ name: 'login' })

      return
    }

    if (store.getters['moduleAuth/GET_USER_DATA'].roles && store.getters['moduleAuth/GET_USER_DATA'].roles.id === 4) {
      SwalFailed(
        `Mohon maaf role ${store.getters['moduleAuth/GET_USER_DATA'].roles.name} tidak dapat mengakses halaman ini`,
        () => {
          store.dispatch('moduleAuth/LOGOUT')
        }
      )

      next()

      return
    }

    if (from.name === 'cargo-finder') store.dispatch('UPDATE_CARGO', { data: [], params: {} })
    if (from.name === 'inbox') store.commit('moduleInbox/SET_PANEL_CHAT', false)
    
    store.dispatch('moduleInbox/LOAD_NOTIF_INBOX')
    
    next()

    return
  }

  if (to.matched.some(record => record.meta.guest)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.getters['moduleAuth/loggedIn'] && store.getters['moduleAuth/GET_USER_DATA'].company && localStorage.getItem ('token')) {
      next({ name: 'app' })

      return
    }
  }

  next()
})


export default router;
