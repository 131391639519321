<template>
  <div id="app">
    <c-header v-if="this.$route.meta.loginRequired" />
    <router-view />
  </div>
</template>

<script>
import cHeader from "./components/cHeader";
export default {
    components: {
        'c-header': cHeader,
    }
};
</script>
