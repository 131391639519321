import 'babel-polyfill'
import Vue from "vue";
import App from "./App.vue";
import store from "./store/store";
import router from "./routes/router";
import BootstrapVue from "bootstrap-vue";
import Vuelidate from "vuelidate";
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';
import vSelect from 'vue-select'

import Echo from 'laravel-echo'
import "./plugins/globals";

Vue.component('VSelect', vSelect)

window.Pusher = require('pusher-js')
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    // key: 'e7d6356ffac0cc1d1c29',
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    // cluster: 'ap1',
    encrypted: false,
    authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
    forceTLS: true,
    auth: {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("token")
        },
    }
})
Vue.use(Vuelidate)
Vue.use(BootstrapVue)

// install rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule])
});
extend('nullable', () => {
    return true;
})

extend('numeric',  {
    message: field => `The ${field} may only contain numeric and can use decimals with '.' characters.`,
    validate: value => new RegExp('(?!^0*$)(?!^0*\\.0*$)^\\d{1,99}(\\.\\d{1,99})?$').test(value)
})

localize('en', en);


// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);


new Vue({
    store: store,
    router,
    render: h => h(App)
}).$mount("#app");
