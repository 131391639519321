import API from "../plugins/axios";
import store from "./store"

const moduleInbox = {
    namespaced: true,
    state: {
        notifInbox: [],
        showPanelChat: false,
        dataPanelChat: {},
        dataMessages: [],
        refresh: {
            scroll: false,
            chat_room: false,
            chatData: false,
            timeout: false,
        },
        currentLength: null
    },
    getters: {

    },
    mutations: {
        SET_NOTIF_INBOX (state, payload) {
            state.notifInbox = payload
        },
        SET_PANEL_CHAT (state, payload) {
            state.showPanelChat = payload
        },
        SET_REFRESH_DATA (state, payload) {
          state.refresh.chatData = payload
        },
        SET_REFRESH_SCROLL (state, payload) {
            state.refresh.scroll = payload
        },
        SET_RESET_TIMEOUT (state) {
            clearTimeout(state.refresh.timeout)
        },
        SET_DATA_MESSAGES (state, payload) {
            state.dataMessages = payload
        },
        SET_CURRENT_LENGTH (state, payload) {
            state.currentLength = payload
        }
    },
    actions: {
        LOAD_NOTIF_INBOX ({commit}) {
            let endpoint = 'chat/total'
            let params = { user_type: 'VS'}
            API.get(endpoint, { params })
                .then(res => {
                    if(res.data.data.chat_total){
                        const data = res.data.data.chat_total[0]
                        commit('SET_NOTIF_INBOX', data)
                    }
                })
        },
        OPEN_MESSAGE ({commit, dispatch}, payload) {
            commit('SET_PANEL_CHAT', false)
            store.commit('SET_LOADING')
            let endpoint = "chat/message/read"
            let params = payload
            return new Promise ((resolve, reject) => {
                API.get(endpoint, { params })
                    .then(async (response) => {
                        const meta = response.data
                        let data = meta.data

                        commit('SET_REFRESH_DATA', true)
                        commit('SET_REFRESH_SCROLL', false)
                        commit('SET_DATA_MESSAGES', data)

                        await dispatch('REFRESH_MESSAGE')
                        resolve()
                    })
                    .catch((error) => {
                        console.log("Open Message", error.response.statusText)
                        reject()
                    })
                    .finally(() => {
                        commit('SET_PANEL_CHAT', true)
                        store.commit('SET_LOADING')
                    })
            })
        },
        async REFRESH_MESSAGE ({state, commit, dispatch}, payload) {
            commit ('SET_CURRENT_LENGTH', state.dataMessages.message.length)
            let endpoint = "chat/message/read";
            let params = {
                shipment: state.dataMessages.detail.shipment.id,
                offer: state.dataMessages.detail.offer.id,
            }
            return new Promise ((resolve, reject) => {
                API.get(endpoint, { params })
                    .then((response) => {
                        const meta = response.data;
                        const data = meta.data;
                        commit('SET_DATA_MESSAGES', data)
                        commit('SET_REFRESH_SCROLL', true)

                        if (state.refresh.chatData && typeof payload === "undefined") {
                            commit('SET_RESET_TIMEOUT')

                            state.refresh.timeout = setTimeout(() => {
                                dispatch('REFRESH_MESSAGE')
                            }, 3000)
                        }
                        resolve()
                    })
                    .catch((error) => {
                        console.log("Refresh message", error)
                        reject()
                    })
            })

        },
        SEND_MESSAGE ({state, dispatch, commit}, payload) {
            let endpoint = "chat/message/send"
            let params = {
                shipment: state.dataMessages.detail.shipment.id,
                offer: state.dataMessages.detail.offer.id,
                user_to: state.dataMessages.detail.pengirim.user.id,
                message: payload
            }
            return new Promise ((resolve, reject) => {
                API.post(endpoint, params)
                    .then(() => {
                        commit('SET_REFRESH_SCROLL', false)
                        dispatch('REFRESH_MESSAGE', true)
                        resolve()
                    })
                    .catch((error) => {
                        console.log("Send message", error.response.statusText);
                        reject()
                    })
            })
        },
    }
}
export default moduleInbox
