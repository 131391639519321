"use strict";

import axios from "axios";
import router from "../routes/router"
import store from "../store/store"
import {SwalFailed} from "./sweetalert2";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.VUE_APP_API_URL || ""
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


function https_axios (token = localStorage.getItem('token') || store.getters.token || null ) {
    // const token = localStorage.getItem('token') || store.getters.token || null
    const config = {
        baseURL: process.env.VUE_APP_API_URL || "",
        timeout: 60 * 1000, // Timeout,
        headers: {
            'Authorization': `Bearer ` + token,
            'Access-Control-Allow-Origin': '*'
        }
        // withCredentials: window.location.hostname !== 'localhost' ? true : false // Check cross-site Access-Control
    }
    const _axios = axios.create(config);

    // Add a request interceptor
    _axios.interceptors.request.use(
        function(config) {
            // Do something before request is sent
            return config;
        },
        function(error) {
            // Do something with request error
            return Promise.reject(error);
        }
    );

    // Add a response interceptor
    _axios.interceptors.response.use(
        function(response) {
            // Do something with response data
            return response;
        },
        function(error) {
            // Do something with response error
            if (error.response.status === 401) {
                SwalFailed (error.response.data.message, () => {
                    localStorage.clear()
                    router.push({ name: 'login' }).catch(() => {})
                })
            }
            return Promise.reject(error);
        }
    );
    return _axios
}


export default https_axios();
